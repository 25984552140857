import createHttp from "@/services/http";
import {
  formatHospitalNames,
  formatHospitalName,
} from "@/services/textFormatters";

export async function getTerritoryHospitals(queryParams, signal) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/territory` + queryParams,
    { signal }
  );
  return formatHospitalNames(response.data);
}

export async function getHospitals(signal) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospitals`,
    { signal }
  );
  return formatHospitalNames(response.data);
}

export async function getHospitalBasicInfo() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/basic_info`
  );
  return formatHospitalNames(response.data);
}

export async function getHospitalTrialFlags(signal) {
  const DEFAULT_TRIAL_FLAGS = {};
  const TIME_LIMIT = 10 * 1000; // 10s

  return new Promise((resolve, reject) => {
    // return default value if the request takes >10s to complete
    const timeoutID = setTimeout(function () {
      resolve(DEFAULT_TRIAL_FLAGS);
    }, TIME_LIMIT);

    createHttp()
      .get(`${import.meta.env.VITE_FLASK_URL}/api/hospitals/trial_flags`, {
        signal,
      })
      .then((response) => {
        clearTimeout(timeoutID);
        resolve(response.data);
      })
      .catch((reason) => {
        // Bubble to parent, who will then handle the error
        reject(reason);
      });
  });
}

export async function getHospitalSummary(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/summary`
  );
  const formattedResult = response.data;
  formattedResult["name"] = formatHospitalName(response.data["name"]);
  return formattedResult;
}

export async function getHospitalProcedureVolumes(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/procedure_volumes`
  );
  return response.data[ccn];
}

export async function getHospitalPhysicians(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/physicians`
  );
  return response.data;
}

export async function getHospitalPhysicianProcedureChart(ccn) {
  const response = await createHttp().get(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/hospital/${ccn}/physicians/procedure_chart`
  );
  return response.data;
}

export async function hospitalSearch(path) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/search?${path}`
  );
  return formatHospitalNames(response.data);
}

export async function getHospitalResearchPayments(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/research_payments`
  );
  return response.data;
}

export async function getHospitalsFilter(query, procModId, signal) {
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/hospitals/filter${
      procModId ? `?proc_mod=${procModId}` : ""
    }`,
    query,
    { signal }
  );
  return response.data;
}

export async function getHealthSystems() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/health_systems`
  );
  return response.data;
}

export async function getGpos() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/gpos`
  );
  return response.data;
}

export async function getHospitalDiagnoses(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/diagnoses`
  );
  return response.data;
}

export async function getHospitalDiagnosisSets(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/diagnosis_sets`
  );
  return response.data;
}

export async function getHospitalAdminContacts(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/admin_contacts`
  );
  return response.data;
}
